import Layout from "../components/layout";
import {useEffect, useState} from "react";
import '../index.css'
import {useNavigate} from "react-router-dom";
import * as React from "react";
import axios from "../axios";

const SerialInquireResult = () => {
    const url = window.location.href.split('/');
    const serial = url[url.length - 1]
    const [inputData, setInputData] = useState('')
    const [listData, setListData] = useState()
    const nullText = '-'
    const navigate = useNavigate()


    const handleInput = (e) => {
        setInputData(e.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButton();
        }
    };

    const handleButton = () => {
        setInputData('')
        navigate(`/serial-inquire/${inputData}`)
    }

    const getListData = async () => {
        try {
            const response = await axios.get(`/serial/inquire?serial=${serial}`)
            setListData(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
            setListData()
        }
    }

    useEffect(() => {
        getListData()
    }, [serial]);

    const addNineHoursAndFormat = (dateStr) => {
        const date = new Date(dateStr);
        date.setHours(date.getHours() + 9);

        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        return formattedDate;
    };

    return (
        <Layout title={'코코케어 플러스 조회'}>
            <div className="filebox">
                <input
                    value={inputData}
                    onChange={handleInput}
                    className="upload-name"
                    placeholder="시리얼번호를 입력해주세요"
                    onKeyDown={handleKeyDown}
                />
                <label onClick={handleButton} htmlFor="file">조회하기</label>
            </div>
            {listData ?
                <div className={'inquireResult'}>
                    <div className={'popup-contents'}>
                        <div className={'popup-key'}>기종</div>
                        <div className={'popup-value'}>{listData?.product_name || nullText}</div>
                        <div className={'popup-key'}>등록일</div>
                        <div className={'popup-value'}>{addNineHoursAndFormat(listData?.create_date) || nullText}</div>
                        <div className={'popup-key'}>만료일</div>
                        <div className={'popup-value'}>{addNineHoursAndFormat(listData?.expiration_date) || nullText}</div>
                    </div>
                </div>
                : <div>
                    <div className={'result-error-text'}>
                        <span>
                        시리얼 번호 조회 결과 내역이 없습니다.
                        </span>
                        <span>
                        시리얼 번호를 다시 입력해주세요.
                        </span>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default SerialInquireResult