import * as React from "react"
import {useState} from "react"
import Layout from "../components/layout"
import "../index.css"
import axios from "../axios"

const DocsRequest = () => {
    const [activeTab, setActiveTab] = useState(1)
    const [formData, setFormData] = useState({
        dType: [],
        company: "",
        name: "",
        phone: "",
        email: "",
        subEmail: "",
        attachment: {},
        content: "",
    })
    const [file, setFile] = useState(null)

    const documentsMapping = {
        "견적서": 1,
        "거래명세서": 2,
        "전자세금계산서": 3,
        "기타": 4,
    }

    const handleTabMenu = () => {
        setActiveTab(activeTab === 1 ? 2 : 1)
        setFormData({
            dType: [],
            company: "",
            name: "",
            phone: "",
            email: "",
            subEmail: "",
            content: "",
            attachment: {},
        })
        setFile(null)
    }

    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target
        const numValue = parseInt(value, 10)

        if (checked) {
            setFormData((prevData) => ({
                ...prevData,
                dType: [...prevData.dType, numValue],
            }))
        } else {
            if (numValue === 3) {
                setFormData((prevData) => ({
                    ...prevData,
                    dType: prevData.dType.filter((type) => type !== numValue),
                    subEmail: "",
                }))
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    dType: prevData.dType.filter((type) => type !== numValue),
                }))
            }
        }
    }

    const handleInputChange = (event) => {
        const {name, value} = event.target
        setFormData((prevData) => ({...prevData, [name]: value}))
    }

    const handleCopyValue = () => {
        setFormData((prevData) => ({...prevData, subEmail: prevData.email}))
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]

        if (!selectedFile) {
            return
        }

        const allowedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"]

        if (allowedTypes.includes(selectedFile.type)) {
            setFile({
                url: URL.createObjectURL(selectedFile),
                type: selectedFile.type.startsWith("image/") ? "image" : "pdf",
                file: selectedFile,
            })
        } else {
            alert("지원되지 않는 파일 형식입니다. 이미지 또는 PDF 파일을 선택해주세요.")
            event.target.value = null
            setFile(null)
        }
    }

    const handleUpload = async () => {
        const imageFormData = new FormData()
        imageFormData.append("files", file.file)

        try {
            const response = await axios.post("/inquery/upload", imageFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            const uploadedFiles = response.data.files

            setFormData((prevData) => ({
                ...prevData,
                attachment: uploadedFiles,
            }))

            return uploadedFiles
        } catch (error) {
            alert("파일 업로드에 실패했습니다.")
            console.error(error)
            throw error
        }
    }

    const handleSubmit = async () => {
        let fields = {
            "서류종류": formData.dType.length === 0,
            "성함": formData.name.trim() === "",
            "전화번호": formData.phone.trim() === "",
            "이메일": formData.email.trim() === "",
        }

        if (activeTab === 2) {
            fields["회사명"] = formData.company.trim() === ""
        }

        if (formData.dType.includes(3)) {
            fields["전자세금계산서 발급용 이메일"] = formData.subEmail.trim() === ""
        }

        const emptyFields = Object.keys(fields).filter((key) => fields[key])

        if (emptyFields.length > 0) {
            alert("빈 칸을 입력해주세요")
        } else {
            try {
                const uploadedFiles = await handleUpload()

                const finalFormData = {...formData, attachment: uploadedFiles}
                const response = await axios.post("http://localhost:8000/inquery/create", finalFormData)
                alert("good")
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    alert("입력을 다시 확인해주세요")
                } else {
                    alert("오류가 발생했습니다. 다시 시도해주세요.")
                }
            }
        }
    }

    return (
        <Layout title={"서류 요청"}>
            <div>
                <button className={activeTab === 1 ? "asRequest-tabButton activeTabButton" : "asRequest-tabButton"}
                        onClick={handleTabMenu}>개인
                </button>
                <button className={activeTab === 2 ? "asRequest-tabButton activeTabButton" : "asRequest-tabButton"}
                        onClick={handleTabMenu}>기업
                </button>
            </div>

            {activeTab === 2 ? (
                <div className={"asRequest-inputs"}>
                    <div className={"asRequest-checkBox"}>
                        <label>서류종류<span className={"input-required"}> *</span></label>
                        {Object.entries(documentsMapping).map(([document, num]) => (
                            <div key={document}>
                                <label className={"asRequest-checkBox-input"}>
                                    <input
                                        type="checkbox"
                                        name={document}
                                        value={num}
                                        checked={formData.dType.includes(num)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {document}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>회사명<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>담당자 성함<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>담당자 전화번호<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>담당자 이메일<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    {formData.dType.includes(3) && (
                        <div className={"asRequest-subEmail"}>
                            <div>
                                전자세금계산서 발급용 이메일
                                <span className={"input-required"}> *</span>
                                <button className={"asRequest-subEmail-button"} onClick={handleCopyValue}>위의 이메일과 동일
                                </button>
                            </div>
                            <input
                                type="text"
                                name="subEmail"
                                value={formData.subEmail}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}
                    <div className={"asRequest-inputs-div"}>
                        <label>사업자등록증<span className={"input-required"}> *</span></label>
                        <div>
                            <div className="filebox">
                                <input
                                    className="upload-name"
                                    value={file?.file?.name || ""}
                                    placeholder="첨부파일"
                                    readOnly
                                />
                                <label htmlFor="file">파일찾기</label>
                                <input type="file" id="file" accept="image/*, .pdf" onChange={handleFileChange}
                                       style={{display: "none"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={"asRequest-inputs-div"}>
                        <label>내용</label>
                        <input
                            type="text"
                            name="content"
                            value={formData.content}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            ) : (
                <div className={"asRequest-inputs"}>
                    <div className={"asRequest-checkBox"}>
                        <label>서류종류<span className={"input-required"}> *</span></label>
                        {Object.entries(documentsMapping).map(([document, num]) => (
                            <div key={document}>
                                <label className={"asRequest-checkBox-input"}>
                                    <input
                                        type="checkbox"
                                        name={document}
                                        value={num}
                                        checked={formData.dType.includes(num)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {document}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className={"asRequest-inputs-div"}>
                        <label>성함<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>전화번호<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>이메일<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    {formData.dType.includes(3) && (
                        <div className={"asRequest-subEmail"}>
                            <div>
                                전자세금계산서 발급용 이메일
                                <span className={"input-required"}> *</span>
                                <button className={"asRequest-subEmail-button"} onClick={handleCopyValue}>위의 이메일과 동일
                                </button>
                            </div>
                            <input
                                type="text"
                                name="subEmail"
                                value={formData.subEmail}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}
                    <div className={"asRequest-inputs-div"}>
                        <label>사업자등록증<span className={"input-required"}> *</span></label>
                        <div>
                            <div className="filebox">
                                <input
                                    className="upload-name"
                                    value={file?.file?.name || ""}
                                    placeholder="첨부파일"
                                    readOnly
                                />
                                <label htmlFor="file">파일찾기</label>
                                <input type="file" id="file" accept="image/*, .pdf" onChange={handleFileChange}
                                       style={{display: "none"}}/>
                            </div>
                        </div>
                    </div>

                    <div>
                        {/* 추가 필드 */}
                    </div>

                    <div className={"asRequest-inputs-div"}>
                        <label>내용</label>
                        <input
                            type="text"
                            name="content"
                            value={formData.content}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            )}
            <div className={"asRequest-button"}>
                <button onClick={handleSubmit}>신청하기</button>
            </div>
        </Layout>
    )
}

export default DocsRequest