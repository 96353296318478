import Layout from "../components/layout";
import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const SerialInquire = () => {
    const [inputData, setInputData] = useState('')
    const navigate = useNavigate()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButton();
        }
    };

    const handleInput = (e) => {
        setInputData(e.target.value)
    }

    const handleButton = () => {
        setInputData('')
        navigate(`/serial-inquire/${inputData}`)
    }

    return (
        <Layout title={'코코케어 플러스 조회'}>
            <div className="filebox">
                <input
                    onChange={handleInput}
                    className="upload-name"
                    value={inputData}
                    placeholder="시리얼번호를 입력해주세요"
                    onKeyDown={handleKeyDown}
                />
                <label onClick={handleButton} htmlFor="file">조회하기</label>
            </div>
        </Layout>
    )
}

export default SerialInquire