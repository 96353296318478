import React from "react"
import Logo from "../images/logo.png"
import "../styles/layout.css"

const Layout = ({ children, title }) => {
	return (
		<div className={"layout"}>
			<div className={"layout-header"}>
				<img src={Logo} />
				<h2>{title}</h2>
			</div>
			<hr />
			<div className={"layout-children"}>
				{children}
			</div>
		</div>
	)
}

export default Layout