import React, {useState, useEffect} from 'react';

const Popup = ({show}) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
        }, 500);
        return () => clearInterval(intervalId);
    }, []);

    if (!show) return null; // show가 false면 null을 반환하여 컴포넌트를 표시하지 않음

    return (
        <div className="popup">
            <div className="popup-content">
                <p>접수 중{dots}</p>
            </div>
        </div>
    );
}

export default Popup;
