import React, {useEffect, useState} from "react"
import "../index.css"
import Layout from "../components/layout"
import axios from "../axios"
import VideoPreview from "../images/video-preview.png"
//image 1~4 나중에 변경 예정
import Image1 from "../images/as-request-image 1.png"
import Image2 from "../images/as-request-image 2.png"
import Image3 from "../images/as-request-image 3.png"
import Image4 from "../images/as-request-image 4.png"
import Popup from "../components/popup";
import AddressInput from "../components/addressInput";
import Text from "../components/text";

const AsRequest = () => {
    const [activeTab, setActiveTab] = useState(1)
    const [formData, setFormData] = useState({
        "company": "",
        "name": "",
        "email": "",
        "phone": "",
        "address": "",
        "purchase_path": "",
        "error": "",
        "serial": "",
        "attachment": {},
    })
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [fileData, setFileData] = useState([])
    const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [detailAddress, setDetailAddress] = useState('')
    const [showLoadingScreen, setShowLoadingScreen] = useState(false);
    const [purchasePath, setPurchasePath] = useState('');
    const [otherValue, setOtherValue] = useState('');
    const [orderNumberValue, setOrderNumberValue] = useState('');
    const [open, setOpen] = useState(false)

    const handlePurchasePathChange = (e) => {
        const value = e.target.value;
        setPurchasePath(value);
        setOtherValue('')
        setOrderNumberValue('')
        setFormData((prevData) => ({...prevData, purchase_path: value}));
    };

    const handleOtherInputChange = (e) => {
        const value = e.target.value;
        setOtherValue(value);
        setFormData((prevData) => ({...prevData, purchase_path: value}));
    };

    const handleOrderNumberInputChange = (e) => {
        const value = e.target.value;
        const regex = /^\d{9}$/;

        if (value === '' || (value.length <= 9 && /^\d*$/.test(value))) {
            setOrderNumberValue(value);

            // 9자리 숫자가 완성되었을 때만 purchase_path를 업데이트합니다.
            if (value.length === 9) {
                setFormData((prevData) => ({
                    ...prevData,
                    purchase_path: `프로즌 공식 홈페이지 #PHZ${value}`
                }));
            }
        }
    }

    const handleAddressChange = (address) => {
        setFormData((prevData) => ({...prevData, address}));
        setIsAddressModalOpen(false); // 주소 선택 후 모달 닫기
    };

    const openAddressModal = () => {
        setIsAddressModalOpen(true);
    };

    const closeAddressModal = () => {
        setIsAddressModalOpen(false);
    };

    const handlePrivacyAgree = () => {
        setIsPrivacyAgreed(!isPrivacyAgreed);
    };

    const handleTabMenu = (idx) => {
        setActiveTab(idx)
        setFormData({
            "company": "",
            "name": "",
            "email": "",
            "phone": "",
            "address": "",
            "purchase_path": "",
            "error": "",
            "serial": "",
            "attachment": {},
        })
        setFiles([])
        setFileData([])
    }
    const handleInputChange = (e) => {
        const {name, value} = e.target
        if (name === 'detailAddress') {
            setDetailAddress(value)
        } else {
            setFormData((prevData) => ({...prevData, [name]: value}))
        }
    }

    const handleFileChange = (event) => {
        if (files.length >= 4) {
            alert("최대 4개의 파일만 첨부 가능합니다.")
            return
        }

        const selectedFiles = Array.from(event.target.files)
        const availableSlots = 4 - files.length
        const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

        const isFileTooLarge = selectedFiles.some(file => file.size > MAX_FILE_SIZE);
        if (isFileTooLarge) {
            alert("파일 크기는 2GB를 초과할 수 없습니다.");
            return;
        }

        const newFiles = selectedFiles.slice(0, availableSlots).filter(file =>
            file.type.startsWith("image/") || file.type.startsWith("video/"),
        ).map(file => ({
            url: URL.createObjectURL(file),
            type: file.type.startsWith("image/") ? "image" : "video",
        }))

        setFileData(prevData => [...prevData, ...Array.from(event.target.files)])
        setFiles(prevFiles => [...prevFiles, ...newFiles])
    }


    const handleDelete = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index))
    }

    const handleUpload = async () => {
        const imageFormData = new FormData()
        fileData.forEach(file => {
            imageFormData.append("files", file);
        });


        try {
            const response = await axios.post("/after_service/upload", imageFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            setFormData((prevData) => ({...prevData, attachment: response.data.files}))
            return response.data.files
        } catch (error) {
            alert(`파일 업로드에 실패했습니다`)
            console.log(error)
            throw error
        }
    }

    const handleSubmit = async () => {
        const fields = {
            "회사명": formData.company.trim(),
            "성함": formData.name.trim(),
            "이메일": formData.email.trim(),
            "전화번호": formData.phone.trim(),
            "주소": formData.address.trim(),
            "구매 경로": formData.purchase_path.trim(),
            "이상증세": formData.error.trim(),
            "시리얼 번호": formData.serial.trim(),
        }

        const emptyFields = Object.keys(fields).filter(key => {
            if (activeTab === 1 && key === "회사명") {
                return false;
            }
            return !fields[key];
        });

        if (emptyFields.length > 0) {
            alert(`${emptyFields.join(", ")}를(을) 입력해주세요`);
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^01[0-9]{8,9}$/;

        if (!emailRegex.test(fields["이메일"])) {
            alert("이메일 형식이 올바르지 않습니다.");
            return;
        }

        if (!phoneRegex.test(fields["전화번호"])) {
            alert("전화번호 형식이 올바르지 않습니다.");
            return;
        }

        if (!isPrivacyAgreed) {
            alert('개인정보 처리방침에 동의해주세요.');
            return;
        }
        setIsLoading(true);
        try {
            let uploadedFiles = {};
            if (files.length > 0) {
                uploadedFiles = await handleUpload();
            }

            const finalFormData = {...formData, attachment: uploadedFiles};

            if (formData.address && detailAddress.trim() !== '') {
                finalFormData.address = `${formData.address.trim()},${detailAddress.trim()}`;
            }

            const response = await axios.post("/after_service/create", finalFormData);
            setIsLoading(false);
            alert("접수가 완료되었습니다");
            setFormData({
                "company": "",
                "name": "",
                "email": "",
                "phone": "",
                "address": "",
                "purchase_path": "",
                "error": "",
                "serial": "",
                "attachment": {},
            });
            setIsAddressModalOpen(false);
            setIsPrivacyAgreed(false);
            setFiles([]);
            setDetailAddress('');
            setPurchasePath('');
            setOpen(false)
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            alert('접수 처리 중 오류가 발생했습니다.');
        }
    };

    useEffect(() => {
        if (isLoading) {
            const timer = setTimeout(() => {
                setShowLoadingScreen(true);
            }, 500); // 500ms 후에 로딩 화면 표시
            return () => clearTimeout(timer);
        } else {
            setShowLoadingScreen(false);
        }
    }, [isLoading]);

    return (
        <Layout title={"수리 접수"}>
            <div>
                <button className={activeTab === 1 ? "asRequest-tabButton activeTabButton" : "asRequest-tabButton"}
                        onClick={() => handleTabMenu(1)}>개인
                </button>
                <button className={activeTab === 2 ? "asRequest-tabButton activeTabButton" : "asRequest-tabButton"}
                        onClick={() => handleTabMenu(2)}>기업
                </button>
            </div>

            <div className={"asRequest-inputs"}>
                {activeTab === 2 ?
                    <div className={"asRequest-inputs-div"}>
                        <label>회사명<span className={"input-required"}> *</span></label>
                        <input
                            type="text"
                            name="company"
                            value={formData.company || ""}
                            onChange={handleInputChange}
                        />
                    </div> : <></>}
                <div className={"asRequest-inputs-div"}>
                    <label>성함<span className={"input-required"}> *</span></label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>이메일<span className={"input-required"}> *</span></label>
                    <input
                        type="text"
                        name="email"
                        value={formData.email || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>전화번호<span className={"input-required"}> *</span></label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone || ""}
                        onChange={handleInputChange}
                        placeholder={'- 없이 입력해주세요'}
                    />
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>AS 수거주소<span className={"input-required"}> *</span></label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address || ""}
                        onClick={openAddressModal}
                        placeholder={'주소 검색'}
                        onChange={handleInputChange}
                        readOnly // 주소는 팝업에서 선택하여 입력하므로, 직접 수정할 수 없게 만듦
                    />
                    <input
                        type="text"
                        name={'detailAddress'}
                        value={detailAddress || ""}
                        onChange={handleInputChange}
                        placeholder={'상세 주소를 입력해주세요'}
                    />
                </div>

                {isAddressModalOpen && (
                    <div>
                        <button className={'address-popup-close'} onClick={closeAddressModal}>닫기</button>
                        <AddressInput onChange={handleAddressChange}/>
                    </div>
                )}
                <div className={"asRequest-inputs-div"}>
                    <label>구매경로<span className={"input-required"}> *</span></label>
                    <select
                        name="purchase_path"
                        value={purchasePath}
                        onChange={handlePurchasePathChange}
                    >
                        <option value="" disabled>구매 경로를 선택해주세요</option>
                        <option value={'코코쓰리디'}>코코쓰리디</option>
                        <option value={'네이버'}>네이버 스마트스토어</option>
                        <option value={'프로즌 공식 홈페이지'}>프로즌 공식 홈페이지</option>
                        <option value={'기타'}>기타</option>
                    </select>
                    {purchasePath === '기타' && (
                        <input
                            type="text"
                            placeholder="구매 경로를 입력해주세요"
                            value={otherValue}
                            onChange={handleOtherInputChange}
                        />
                    )}
                    {purchasePath === '프로즌 공식 홈페이지' && (
                        <div className={'text-number'}>
                            <div>
                                Order Number: <span>#PHZ</span>
                            </div>
                            <input
                                type="text"
                                placeholder="주문 번호 숫자 9자리를 입력해주세요"
                                value={orderNumberValue}
                                onChange={handleOrderNumberInputChange}
                            />
                        </div>
                    )}
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>이상증세<span className={"input-required"}> *</span></label>
                    <textarea
                        name="error"
                        value={formData.error || ""}
                        placeholder={'상세하게 기입해주시면 원활하게 처리가 가능합니다'}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>시리얼번호<span className={"input-required"}> *</span></label>
                    <input
                        type="text"
                        name="serial"
                        value={formData.serial || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={"asRequest-inputs-div"}>
                    <label>참고자료</label>
                    <span>문제가 잘 보이는 사진을 첨부해주세요</span>
                    <div className={"asRequest-images"}>
                        <img src={Image1}/>
                        <img src={Image2}/>
                        {/*<img src={Image1}/>*/}
                        {/*<img src={Image2}/>*/}
                        {/*<img src={Image3}/>*/}
                        {/*<img src={Image4}/>*/}
                    </div>
                    <div>
                        <div className="filebox">
                            <label htmlFor="file">파일찾기</label>
                            <input type="file" id="file" accept="image/*,video/*" multiple
                                   onChange={handleFileChange} style={{display: "none"}}/>
                        </div>
                        <div className="thumbnails">
                            {files.map((file, index) => (
                                <div key={index} className="thumbnail-wrapper">
                                    {file.type === "image" ?
                                        <img src={file.url} alt="thumbnail" width="100"/> :
                                        <img src={VideoPreview} alt={"thumbnail"} width={"100"}/>
                                    }
                                    <button onClick={() => handleDelete(index)}>삭제</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="asRequest-checkbox">
                    <input
                        type="checkbox"
                        id="privacyAgreement" // id 속성 추가
                        checked={isPrivacyAgreed}
                        onChange={handlePrivacyAgree}
                    />
                    <label htmlFor="privacyAgreement"> {/* htmlFor 속성을 추가하여 체크박스와 연결 */}
                        개인정보 처리방침에 동의합니다.<span className="input-required"> *</span>
                    </label>
                    <span onClick={() => setOpen(!open)}>자세히보기</span>
                </div>
                {open && <Text></Text>}
            </div>
            <div className={"asRequest-button"}>
                <button onClick={handleSubmit}>신청하기</button>
            </div>
            <Popup show={showLoadingScreen}/>
        </Layout>
    )
}

export default AsRequest