import Layout from "../components/layout";
import {useEffect, useState} from "react";
import '../index.css'
import {useNavigate} from "react-router-dom";
import * as React from "react";
import axios from "../axios";

const AsInquireResult = () => {
    const url = window.location.href.split('/');
    const asId = url[url.length - 1]
    const [inputData, setInputData] = useState('')
    const [listData, setListData] = useState()
    const nullText = '-'
    const navigate = useNavigate()
    const processSteps = ["as 접수", "택배 입고", "보고서 전송", "수리 진행"];


    const handleInput = (e) => {
        setInputData(e.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButton();
        }
    };

    const handleButton = () => {
        setInputData('')
        navigate(`/as-inquire/${inputData}`)
    }

    const statusMap = {
        0: 0,
        1: 1,
        2: 1,
        3: 2,
        4: 2,
        5: 3,
        6: 3,
        '-2': 3
    };
    const getListData = async () => {
        try {
            const response = await axios.get(`/after_service/inquire?as_id=${asId}`)
            setListData(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
            setListData()
        }
    }

    const downloadFile = async (filename) => {
        try {
            const response = await axios.get(`/download_file?filename=${filename}`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/json'
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const downloadName = Object.keys(listData.attachment).find(key => listData.attachment[key] === filename); // filename으로 key 찾기
            link.setAttribute('download', downloadName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log("Error downloading the file:", error);
        }
    };


    useEffect(() => {
        getListData()
    }, [asId]);

    const addNineHoursAndFormat = (dateStr) => {
        const date = new Date(dateStr);
        date.setHours(date.getHours() + 9);

        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
        return formattedDate;
    };

    return (
        <Layout title={'수리 조회'}>
            <div className="filebox">
                <input
                    type={"number"}
                    value={inputData}
                    onChange={handleInput}
                    className="upload-name"
                    placeholder="접수번호를 입력해주세요"
                    onKeyDown={handleKeyDown}
                />
                <label onClick={handleButton} htmlFor="file">조회하기</label>
            </div>
            {listData ?
                <div className={'inquireResult'}>
                    {listData?.status === 7 &&
                        <div className={'finish-step'}>수리 완료 후 발송이 완료되었습니다</div>
                    }
                    <div className="popup-progress-step">
                        {processSteps.map((step, index) => (
                            <div
                                key={index}
                                className={`popup-step ${statusMap[listData?.status] === index ? 'popup-active' : ''}`}
                            >
                                {step}
                            </div>
                        ))}
                    </div>
                    <div className={'popup-contents'}>
                        <div className={'popup-key'}>접수번호</div>
                        <div className={'popup-value'}>{listData?.asId || nullText}</div>
                        <div className={'popup-key'}>접수일자</div>
                        <div className={'popup-value'}>{addNineHoursAndFormat(listData?.createDate) || nullText}</div>
                        <div className={'popup-key'}>수거일자</div>
                        <div className={'popup-value'}>{listData?.returnDate?.substr(0, 10) || nullText}</div>
                        <div className={'popup-key'}>이름</div>
                        <div className={'popup-value'}>{listData?.name || nullText}</div>
                        <div className={'popup-key'}>전화번호</div>
                        <div className={'popup-value'}>{listData?.phone || nullText}</div>
                        <div className={'popup-key'}>회사명</div>
                        <div className={'popup-value'}>{listData?.company || nullText}</div>
                        <div className={'popup-key'}>주소</div>
                        <div className={'popup-value'}>{listData?.address || nullText}</div>
                        <div className={'popup-key'}>배송방법</div>
                        <div className={'popup-value'}>{listData?.deliveryId || nullText}</div>
                        <div className={'popup-key'}>반려사유/지연사유</div>
                        <div className={'popup-value'}>{listData?.cancel_reason || nullText}</div>
                        <div className={'popup-key'}>증상</div>
                        <div className={'popup-value'}>{listData?.error || nullText}</div>
                        <div className={'popup-key'}>시리얼번호</div>
                        <div className={'popup-value'}>{listData?.serial || nullText}</div>
                        <div className={'popup-key'}>수리비용</div>
                        <div className={'popup-value'}>{listData?.price || nullText}</div>
                        <div className={'popup-key'}>참고자료</div>
                        <div className={"asRequest-images"}>
                            {Object.entries(listData?.attachment).map(([displayName, filename]) => (
                                <button className={'file-download-button'} key={filename}
                                        onClick={() => downloadFile(filename)}>
                                    파일 다운로드 {displayName}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                : <div>
                    <div className={'result-error-text'}>
                        <span>
                        수리 내역이 없습니다.
                        </span>
                        <span>
                        접수 번호를 다시 입력해주세요.
                        </span>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default AsInquireResult