import DocsRequest from "./pages/docs-request"
import {Route, Routes} from "react-router-dom";
import AsRequest from "./pages/as-request";
import NotFound from "./pages/notFound";
import AsInquire from "./pages/as-inquire";
import AsInquireResult from "./pages/as-inquire-result";
import SerialInquire from "./pages/serial-inquire";
import SerialInquireResult from "./pages/serial-inquire-result";

function App() {
    return (
        <Routes>
            <Route path={'*'} element={<NotFound/>}/>
            <Route path={'/as-request'} element={<AsRequest/>}/>
            <Route path={'/docs-request'} element={<DocsRequest/>}/>
            <Route path={'/as-inquire'} element={<AsInquire/>}/>
            <Route path={'/as-inquire/*'} element={<AsInquireResult/>}/>
            <Route path={'/serial-inquire'} element={<SerialInquire/>}/>
            <Route path={'/serial-inquire/*'} element={<SerialInquireResult/>}/>
        </Routes>
    )
}

export default App