import React from 'react';
import DaumPostcode from 'react-daum-postcode';

const AddressInput = ({onChange}) => {
    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        onChange(fullAddress); // 부모 컴포넌트로 주소 데이터 전달
    };

    return (
        <div className={'address-popup'}>
            <DaumPostcode onComplete={handleComplete}/>
        </div>
    )
};

export default AddressInput;
